import React, { Component } from 'react';

class PersonalDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className='container'>
                <div className='card shadow mt-4 mb-4'>
                    <div className='card-header'>
                        <strong>Personal Details</strong>
                    </div>
                    <div class='card-body'>
                        <form>
                            <p class='mb-2'>
                                <strong>Name</strong>
                            </p>
                            <div class='form-row'>
                                <div class='form-group col-md-6'>
                                    <label for='familyName'>Family Name</label>
                                    <input type='text' class='form-control' id='familyName' placeholder='Family Name' />
                                </div>
                                <div class='form-group col-md-6'>
                                    <label for='givenName'>Given Name</label>
                                    <input type='text' class='form-control' id='givenName' placeholder='Given Name' />
                                </div>
                            </div>
                            <div class='form-group'>
                                <label for='placeOfBirth'>Place Of Birth</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='placeOfBirth'
                                    placeholder='Place Of Birth'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='date-input1'>Date Of Birth</label>
                                <div class='input-group'>
                                    <input
                                        type='date'
                                        class='form-control drgpicker'
                                        id='date-input1'
                                        value='04/04/2020'
                                        aria-describedby='button-addon2'
                                    />
                                    <div class='input-group-append'>
                                        <div class='input-group-text' id='button-addon-date'>
                                            <span class='fe fe-calendar fe-16'></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='form-group'>
                                <label for='gender'>Gender</label>
                                <select class='form-control' id='gender'>
                                    <option value=''>Select your gender</option>
                                    <option value='male'>Male</option>
                                    <option value='female'>Female</option>
                                </select>
                            </div>
                            <div class='form-group'>
                                <label for='nationality'>Nationality</label>
                                <input type='text' class='form-control' id='nationality' placeholder='Nationality' />
                            </div>

                            <p class='mb-2'>
                                <strong>Passport</strong>
                            </p>
                            <div class='form-group'>
                                <label for='passportNumber'>Passport Number</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='passportNumber'
                                    placeholder='Passport Number'
                                />
                            </div>
                            <div class='form-row'>
                                <div class='form-group col-md-6'>
                                    <label for='dateOfIssue'>Date Of Issue</label>
                                    <div class='input-group'>
                                        <input
                                            type='date'
                                            class='form-control'
                                            id='dateOfIssue'
                                            value='04/04/2020'
                                            aria-describedby='button-addon2'
                                        />
                                    </div>
                                </div>
                                <div class='form-group col-md-6'>
                                    <label for='expiredDate'>Expired Date</label>
                                    <div class='input-group'>
                                        <input
                                            type='date'
                                            class='form-control'
                                            id='expiredDate'
                                            value='04/04/2020'
                                            aria-describedby='button-addon2'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class='form-group'>
                                <label for='issuingOffice'>Issuing Office</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='issuingOffice'
                                    placeholder='Issuing Office'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='home-phone-number'>Home Phone Number</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='home-phone-number'
                                    placeholder='Home Phone Number'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='mobile-number'>Mobile Number</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='mobile-number'
                                    placeholder='Mobile Number'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='email'>Email</label>
                                <input type='text' class='form-control' id='email' placeholder='Email' />
                            </div>
                        </form>
                    </div>
                    <div className='card-footer d-flex justify-content-end'>
                        <a href='/contactDetails' className='btn btn-primary'>
                            Next
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default PersonalDetails;
