import React, { Component } from 'react';

class QualificationStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardElement: [],
        };
    }

    componentDidMount(){
        this.renderCard()
    }

    renderCard = () => {
        const {cardElement} = this.state
        let element = (
            <div class='card-body'>
                <div class='form-group'>
                    <label for='level-study'>Level of Study</label>
                    <input type='text' class='form-control' id='level-study' placeholder='Level of Study' />
                </div>
                <div class='form-group'>
                    <label for='institution-name'>Name of Institution</label>
                    <input
                        type='text'
                        class='form-control'
                        id='institution-name'
                        placeholder='Name of Institution'
                    />
                </div>
                <div class='form-group'>
                    <label for='state-province'>State/Province</label>
                    <input
                        type='text'
                        class='form-control'
                        id='state-province'
                        placeholder='State/Province'
                    />
                </div>
                <div class='form-group'>
                    <label for='country-mail'>Country</label>
                    <input type='text' class='form-control' id='country-mail' placeholder='Country' />
                </div>
                <div class='form-group'>
                    <label for='language-of-instruction'>Language of Instruction</label>
                    <input
                        type='text'
                        class='form-control'
                        id='language-of-instruction'
                        placeholder='Language of Instruction'
                    />
                </div>
                <div class='form-group'>
                    <label for='status'>Status</label>
                    <input type='text' class='form-control' id='status' placeholder='Status' />
                </div>
                <div class='form-row'>
                    <div class='form-group col-md-6'>
                        <label for='start-date'>Start Date</label>
                        <div class='input-group'>
                            <input
                                type='date'
                                class='form-control'
                                id='start-date'
                                value='04/04/2020'
                                aria-describedby='button-addon2'
                            />
                        </div>
                    </div>
                    <div class='form-group col-md-6'>
                        <label for='end-date'>End Date</label>
                        <div class='input-group'>
                            <input
                                type='date'
                                class='form-control'
                                id='end-date'
                                value='04/04/2020'
                                aria-describedby='button-addon2'
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
        this.state.cardElement.push(element);
        this.setState({ cardElement });
    };

    render() {
        const { cardElement } = this.state;
        return (
            <div className='container'>
                <div className='card shadow mt-4 mb-4'>
                    <div className='card-header'>
                        <strong>Qualification Study</strong>
                    </div>
                    <div className='card shadow mt-2'>
                        <div className='card-body'>
                            {cardElement.map((card) => card)}
                            <div className='card-footer d-flex justify-content-end'>
                                <button className='btn btn-primary' onClick={this.renderCard}>
                                    + Add
                                </button>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-between'>
                            <a href='/proposedStudy' className='btn btn-secondary'>
                                Previous
                            </a>
                            <a href='/uploadDocuments' className='btn btn-primary'>
                                Next
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default QualificationStudy;
