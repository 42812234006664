import React, { Component } from 'react';

class ContactDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className='container'>
                <div className='card shadow mt-4 mb-4'>
                    <div className='card-header'>
                        <strong>Contact Details</strong>
                    </div>
                    <div className='card-body'>
                        <form>
                            <p class='mb-2'>
                                <strong>Residential Address</strong>
                            </p>
                            <div class='form-group'>
                                <label for='address-line1'>Address line 1</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='address-line1'
                                    placeholder='Address line 1'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='address-line2'>Address line 2</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='address-line2'
                                    placeholder='Address line 2'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='town-city'>Town/City</label>
                                <input type='text' class='form-control' id='town-city' placeholder='Town/City' />
                            </div>
                            <div class='form-group'>
                                <label for='postal-code'>Postal Code</label>
                                <input type='text' class='form-control' id='postal-code' placeholder='Postal Code' />
                            </div>
                            <div class='form-group'>
                                <label for='state-province'>State/Province</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='state-province'
                                    placeholder='State/Province'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='country'>Country</label>
                                <input type='text' class='form-control' id='country' placeholder='Country' />
                            </div>
                            <p class='mb-2'>
                                <strong>Mailing Address</strong>
                            </p>
                            <div class='form-group'>
                                <label for='address-line1-mail'>Address line 1</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='address-line1-mail'
                                    placeholder='Address line 1'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='address-line2-mail'>Address line 2</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='address-line2-mail'
                                    placeholder='Address line 2'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='town-city-mail'>Town/City</label>
                                <input type='text' class='form-control' id='town-city-mail' placeholder='Town/City' />
                            </div>
                            <div class='form-group'>
                                <label for='postal-code-mail'>Postal Code</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='postal-code-mail'
                                    placeholder='Postal Code'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='state-province-mail'>State/Province</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='state-province-mail'
                                    placeholder='State/Province'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='country-mail'>Country</label>
                                <input type='text' class='form-control' id='country-mail' placeholder='Country' />
                            </div>
                        </form>
                    </div>
                    <div className='card-footer d-flex justify-content-between'>
                        <a href='/' className='btn btn-secondary'>
                            Previous
                        </a>
                        <a href='/emergencyContactDetails' className='btn btn-primary'>
                            Next
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactDetails;
