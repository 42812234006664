import React, { Component } from 'react';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className='container'>
                <div className='card shadow'>
                    <nav class='topnav navbar navbar-light d-flex justify-content-center'>
                        <div className=''>
                            <h5 className='text-center'>Sunan Kalijaga Global Scholarship 2021 Application Form</h5>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

export default Header;
