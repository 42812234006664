import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import PersonalDetails from '../components/form/PersonalDetails';
import ContactDetails from '../components/form/ContactDetails';
import EmergencyContactDetails from '../components/form/EmergencyContactDetails';
import ProposedStudy from '../components/form/ProposedStudy';
import QualificationStudy from '../components/form/QualificationStudy';
import UploadDocument from '../components/form/UploadDocument';
import Header from '../components/Header'

class router extends Component {
    // toDashboard = (props) => {
    //     const token = localStorage.getItem('token');

    //     if (token) {
    //         return <Dashboard {...props} />;
    //     } else {
    //         return <Redirect to='/' />;
    //     }
    // };

    render() {
        return (
            <Router>
                <div>
                    <Header />
                    <Switch>
                        <Route exact path='/' component={PersonalDetails} />
                        <Route path='/contactDetails' component={ContactDetails} />
                        <Route path="/emergencyContactDetails" component={EmergencyContactDetails} />
                        <Route path='/proposedStudy' component={ProposedStudy} />
                        <Route path='/qualificationStudy' component={QualificationStudy} />
                        <Route path='/uploadDocuments' component={UploadDocument} />
                        {/* <Route path='/dashboard' component={(props) => this.toDashboard(props)} />
                        <Route path='*' component={NotFoundPage} /> */}
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default router;
