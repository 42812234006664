import React, { Component } from 'react';

class EmergencyContactDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className='container'>
                <div className='card shadow mt-4 mb-4'>
                    <div className='card-header'>
                        <strong>Emergency Contact</strong>
                    </div>
                    <div className='card-body'>
                        <div class='form-group'>
                            <label for='name'>Name</label>
                            <input type='text' class='form-control' id='name' placeholder='Name' />
                        </div>
                        <div class='form-group'>
                            <label for='gender'>Gender</label>
                            <select class='form-control' id='gender'>
                                <option value=''>Select your gender</option>
                                <option value='male'>Male</option>
                                <option value='female'>Female</option>
                            </select>
                        </div>
                        <div class='form-group'>
                            <label for='relation'>Relationship to you</label>
                            <input type='text' class='form-control' id='relation' placeholder='Relationship to you' />
                        </div>
                        <div class='form-group'>
                            <label for='home-phone-number'>Home Phone Number</label>
                            <input
                                type='text'
                                class='form-control'
                                id='home-phone-number'
                                placeholder='Home Phone Number'
                            />
                        </div>
                        <div class='form-group'>
                            <label for='mobile-number'>Mobile Number</label>
                            <input type='text' class='form-control' id='mobile-number' placeholder='Mobile Number' />
                        </div>
                        <div class='form-group'>
                            <label for='email'>Email</label>
                            <input type='text' class='form-control' id='email' placeholder='Email' />
                        </div>
                        <div class='form-group'>
                            <label for='address-line1'>Address line 1</label>
                            <input type='text' class='form-control' id='address-line1' placeholder='Address line 1' />
                        </div>
                        <div class='form-group'>
                            <label for='address-line2'>Address line 2</label>
                            <input type='text' class='form-control' id='address-line2' placeholder='Address line 2' />
                        </div>
                        <div class='form-group'>
                            <label for='town-city'>Town/City</label>
                            <input type='text' class='form-control' id='town-city' placeholder='Town/City' />
                        </div>
                        <div class='form-group'>
                            <label for='postal-code'>Postal Code</label>
                            <input type='text' class='form-control' id='postal-code' placeholder='Postal Code' />
                        </div>
                        <div class='form-group'>
                            <label for='state-province'>State/Province</label>
                            <input type='text' class='form-control' id='state-province' placeholder='State/Province' />
                        </div>
                        <div class='form-group'>
                            <label for='country'>Country</label>
                            <input type='text' class='form-control' id='country' placeholder='Country' />
                        </div>
                    </div>
                    <div className='card-footer  d-flex justify-content-between'>
                        <a href='/contactDetails' className='btn btn-secondary'>
                            Previous
                        </a>
                        <a href='/proposedStudy' className='btn btn-primary'>
                            Next
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmergencyContactDetails;
