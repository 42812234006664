import React, { Component } from 'react';

class ProposedStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className='container'>
                <div className='card shadow mt-4 mb-4'>
                    <div className='card-header'>
                        <strong>Proposed Study</strong>
                    </div>
                    <div className='card-body'>
                        <form>
                            <div class='form-group'>
                                <label for='type-of-study'>Type of Study</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='type-of-study'
                                    placeholder='Type of Study'
                                />
                            </div>
                            <div class='form-group'>
                                <label for='faculty'>Faculty</label>
                                <input type='text' class='form-control' id='faculty' placeholder='Faculty' />
                            </div>
                            <div class='form-group'>
                                <label for='study-program'>Study Program</label>
                                <input
                                    type='text'
                                    class='form-control'
                                    id='study-program'
                                    placeholder='Study Program'
                                />
                            </div>
                            <p className='mb-2'>
                                <strong>Funding</strong>
                            </p>
                            <div class='form-group'>
                                <label for='funding'>Funding</label>
                                <select class='form-control' id='funding'>
                                    <option value=''>Select your funding</option>
                                    <option value='self-funded'>Self-funded</option>
                                    <option value='sunan-kalijaga-global-scholarship-2021'>
                                        Sunan Kalijaga Global Scholarship 2021
                                    </option>
                                </select>
                            </div>
                            <p>
                                <strong>Language Proficiency</strong>
                            </p>
                            <div class='form-row'>
                                <div className='form-group col-md-6'>
                                    <label for='type'>Type</label>
                                    <input type='text' class='form-control' id='type' placeholder='Type' />
                                </div>
                                <div className='form-group col-md-6'>
                                    <label for='score'>Score</label>
                                    <input type='text' class='form-control' id='score' placeholder='Score' />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='card-footer d-flex justify-content-between'>
                        <a href='/emergencyContactDetails' className='btn btn-secondary'>
                            Previous
                        </a>
                        <a href='/qualificationStudy' className='btn btn-primary'>
                            Next
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProposedStudy;
