import React, { Component } from 'react';

class UploadDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className='container'>
                <div class='card shadow mb-4 mt-4'>
                    <div class='card-header'>
                        <strong>Upload Documents</strong>
                    </div>
                    <div class='card-body'>
                        <form>
                            <div className='form-group'>
                                <p>Latest Photo</p>
                                <input type='file' id='latest-photo' />
                            </div>
                            <div className='form-group'>
                                <p>Curriculum Vitae</p>
                                <input type='file' id='curriculum-vitae' />
                            </div>
                            <div className='form-group'>
                                <p>Scanned Passport (Bio-data page only)</p>
                                <input type='file' id='scanned-passport' />
                            </div>
                            <div className='form-group'>
                                <p>Scanned Language Proficiency</p>
                                <input type='file' id='scanned-language-proficiency' />
                            </div>
                            <div className='form-group'>
                                <p>Scanned of Certificate of Previous Study</p>
                                <input type='file' id='certificate-previous-study' />
                            </div>

                            <div className='form-group'>
                                <p>Scanned of Transcript of Previous Study</p>
                                <input type='file' id='transcript-previous-study' />
                            </div>
                        </form>
                    </div>
                    <div className='card-footer d-flex justify-content-between'>
                        <a href='/proposedStudy' className='btn btn-secondary'>
                            Previous
                        </a>
                        <button className='btn btn-primary'>Submit</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default UploadDocument;
