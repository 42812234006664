import React from 'react';
import Router from './routes';

function App() {
    return (
        // <Router />
        <div className='container'>
            <div className='card shadow mt-4 mb-4'>
                <div className='card-header'>
                    <center>
                        <strong>Submission Closed</strong>
                    </center>
                </div>
                <div className='card-body'></div>
            </div>
        </div>
    );
}

export default App;
